.testimonial-header{
    margin-top: 10%;
    /* text-align: center; */
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 5%;
}

.testimonial-header > #hr{
    border-bottom: 1px solid #48C8F0;
    height: 2px;
}

.testimonial-header #bold-sec-hr{
    width: 150px;
    height: 7px;
    background-color: #48C8F0;
    /* float: left; */
    border: 1px solid #48C8F0;
    clip-path: polygon(0px -1px, 100% -1px, calc(100% - 10px) 100%, 10px 100%);
}

.testimonial-header > h4{
    font-size: 30px;
    font-weight:600;
    letter-spacing: 4px;
    color :#48C8F0;
}

.testimonial-content{
    margin-top: 3%;
    padding-left: 8%;
    margin-bottom: 5%;
    padding-right: 8%;
}

.testimonial-row{
    display: flex;
    flex-direction: row;
}

.testimonial-col{
    -ms-flex: 50%; /* IE10 */
    flex: 50%;
    max-width: 50%;
    padding-left: 2%;
    padding-right: 2%;
}

.testimonial-col1{
    -ms-flex: 50%; /* IE10 */
    flex: 50%;
    max-width: 50%;
    /* margin-top: 2%; */
    padding-left: 2%;
    padding-right: 2%;
}

@media screen and (max-width: 1000px) {
    .testimonial-row{
        display: flex;
        flex-direction: column;
    }
    
    .testimonial-col, .testimonial-col1{
        -ms-flex: 100%; /* IE10 */
        flex: 100%;
        max-width: 100%;
        padding-left: 5%;
        padding-right: 5%;
        padding-bottom: 10%;
        margin-top: 0%;
    }
}

.test-content-grid {
    box-shadow: 10px 10px 20px 12px rgba(72,200,240, 0.7);
    border-radius: 5px;
    transition: transform .5s; /* Animation */
    padding: 5%;
    cursor: pointer;
    vertical-align: middle;
    background: white;
  }

.test-content-grid:hover{
      transform: scale(1.07);
}

.test-name{
    display: flex;
    flex-direction: row;
}

.test-img{
    padding-top: 2%;
    flex: 30%;
}

.test-img > img{
    width: 70%;
    /* height: 100%; */
    border-radius: 50%;
}

.test-name-text{
    flex: 70%;
}

.test-name-text > b{
    font-size: 18px;
    color: #48C8F0;
}

.test-name-text > p{
    font-size: 15px;
}

.test-content{
    font-size: 15px;
    padding-bottom: 2%;
    padding-top: 2%;
}

