footer{
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: black;
    position: relative;
    color: white;
    padding-top: 5%;
    text-align: center;
    font-size: 18px;
    padding-bottom: 1%;
}

.footer-widget {
    padding-top: 7%;
    width: 100%;
    background: rgba(0, 0, 0, 0.86);
    padding: 10px;
  }

  
.icons {
    padding: 15px 15px;
    font-size: 20px;
    color: #fff;
}

.icons:hover {
    opacity: 0.7;
}
  
.social-media {
    list-style: none;
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    /* overflow-wrap: 'break-word' */
  }
  